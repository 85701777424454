import React from "react";
import { graphql, Link } from "gatsby";

import PageLayout from "../components/page-layout";

export default function DiversityPage({ data }) {
  return (
    <PageLayout>
      <section>
        <h1>Success</h1>
        <p>Thank you for helping fellow diverse professionals!</p>
        <Link to="/">Back to the main site</Link>
      </section>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.svg" }) {
      publicURL
    }
  }
`;
